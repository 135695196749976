<template> 
<div v-if="perms.includes('Catégories') ||  perms.includes('Admin')">
   <div v-if="loading">
          <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <b-card v-else>
       <div class="custom-search">

        <b-row>
          <b-col 
            md="3"
          >
              <b-button
                variant="primary"
                v-b-toggle href="#add-user-sidebar" @click.prevent
                
                
              >
              <!--  v-b-toggle href="#add-user-sidebar" @click.prevent -->
              <!-- @click="isAddNewUserSidebarActive = true" -->
                <span class="text-nowrap">Nouvelle Catégorie</span>
              </b-button>
            <!-- </div> -->
          </b-col>
          <b-col 
            md="9"
          >              
          <b-form-input
            v-model="searchTerm"
            placeholder="Chercher"
            type="text"
            class="d-inline-block mr-1"
          />
          </b-col>
          
        </b-row>

      </div>
    <br/>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="users"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span class="text-nowrap"><center>{{ props.row.name }}</center></span>
        </div>
        <div
          v-else-if="props.column.field === 'desc'"
          class="text-nowrap"
        >
          <span class="text-nowrap"><center>{{ props.row.desc }}</center></span>
        </div>
        <div
          v-else-if="props.column.field === 'NbProd'"
          class="text-nowrap"
        >
          <span class="text-nowrap"><center><b>{{ props.row.NbProd }}</b> Produits </center></span>
        </div>      
       

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
           <span><b-row> <b-button style="margin-right: 4px; margin-left: 5px;"
                        variant="gradient-success"
                        class="btn-icon"
                      v-b-toggle href="#update-user-sidebar" @click.prevent @click="showUpdate(props.row)"
                        >
                       <feather-icon icon="Edit2Icon" />
                        
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon"
                        @click="DeleteCat(props.row.id)"
                        >
                        <feather-icon icon="TrashIcon" />
                        
                    </b-button>
                    </b-row></span>
 
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['8','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card>
  <b-sidebar 
    id="add-user-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           Nouvelle Catégorie
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
  
        <b-form-group
          label="Libelle"
          label-for="lib"
        >
          <b-form-input
            id="lib"
            v-model="name"
            trim
          />
        </b-form-group>
         <b-form-group
              label="Description"
              label-for="Desc"
            >
                <b-form-textarea
                    id="desc"
                    placeholder="Description d'une catégorie"
                    rows="4"
                    max-rows="8"
                    no-auto-shrink
                    v-model="desc"
                />
          </b-form-group>
        
      
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="NewCat()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
   <b-sidebar 
    id="update-user-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           Modifier La Catégorie
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
  
        <b-form-group
          label="Libelle"
          label-for="libelle"
        >
          <b-form-input
            id="libelle"
            v-model="form.name"
            trim
          />
        </b-form-group>
        <b-form-group
              label="Description"
              label-for="Desc"
            >
                <b-form-textarea
                    id="desc"
                    placeholder="Description d'une catégorie"
                    rows="4"
                    max-rows="8"
                    no-auto-shrink
                    v-model="form.desc"
                />
          </b-form-group>
                    <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="UpdateCat()"
          >
            Modifier
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</div>
<div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import {
 BButton,BCard, BRow, BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown,
  BDropdownItem,VBToggle,BCollapse,BSidebar,BForm,BFormSpinbutton,BFormCheckbox,BFormTextarea

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
// import UserListAddNew from './UserListAddNew.vue'
// import UserListUpdate from './UserListUpdate.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import NotAuthoriz from '../NotAuthoriz.vue'
// import { codeColumnSearch } from './code'

export default {
  components: {
    BCard,VBToggle,BCollapse,BSidebar,BForm,BFormSpinbutton,BFormCheckbox,
    VueGoodTable,
    BRow, BCol,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    vSelect,
    NotAuthoriz
    
  },
        directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      perms:[],
      loading : false,
      pageLength: 8,
      dir: false,
      UserID:{},
      isAddNewUserSidebarActive:false,
      isUpdateUserSidebarActive:false,
      columns: [
        {
          label: 'Libelle',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher libelle',
          },
        },
         {
          label: 'Description',
          field: 'desc',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Desc',
          },
        },
        {
          label: 'Nombre des produits',
          field: 'NbProd',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher number',
          },
        },
       
       
        {
          label: 'Action',
          field: 'action',
        },
      ],
      users: [],
      searchTerm: '',
      name:'',
      desc:'',
       form :new Form({    
          id:0,
          name:'',
          desc:'',
          }),
         
    }
  },
  methods:{
    getCategory(){
      this.loading = true
       this.$http.get(`${config.API_BASE_URL}/api/auth/getCategories`)
      .then(res => { this.users=res.data , this.loading = false})
    },
    NewCat(){
          let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('name', this.name);
          data.append('desc', this.desc);
          // console.log(Object.fromEntries(data))
        this.$http.post(`${config.API_BASE_URL}/api/auth/AddCategory`,data) .then(res => {   
            if(res.data=='success'){
               document.getElementById("formdata").reset();
              this.getCategory();
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Catégorie est bien crée',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })
    },
    showUpdate(com){
      // console.log(com)
      this.form.reset();
      this.form.fill(com);
    },
    UpdateCat(){
        let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('name', this.form.name);
          data.append('desc', this.form.desc);
          // console.log(Object.fromEntries(data))
          console.log(this.form)
        this.$http.post(`${config.API_BASE_URL}/api/auth/EditCategory/${this.form.id}`,data
        // ,{
        //   headers: {
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        //   "Access-Control-Allow-Headers": "Access-Control-Allow-Origin, X-Requested-With, X-Auth-Token, Content-Type, Content-Range, Content-Disposition,Content-Description, x_-xsrf-token,ip, Authorization"
        // }
        // }
        ) .then(res => {  
           
            if(res.data=='success'){
              this.getCategory();
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Catégorie est bien modifié',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })
    },
    DeleteCat(id){
      let data= new FormData;
      data.append('UserID', this.UserID.id);
       this.$swal.fire({
            text: "Vous voulez supprimer cette catégorie ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
          }).then((result) => {
            if (result.value) {
                  this.$http.post(`${config.API_BASE_URL}/api/auth/DeleteCategory/${id}`,data)
                  .then(res=> {
                        if(res.data=='Success'){
                        this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Catégorie est bien supprimée',
                        showConfirmButton: false,
                        timer: 1500  })   
                        this.getCategory(); 
                      }else if(res.data=='error'){
                          this.$swal.fire({
                            icon: 'error',
                            title: 'Erreur de suppression',
                            text: 'Vous pouvez pas supprimer cette catégorie'
                          }); }   
                  }).catch(() => {
                      this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! Pleaz try again'
                          });
                  })
              }  })
      }
  
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Admin      : 'light-info',
        admin      : 'light-info',
        Editeur : 'light-success',
        Autre     : 'light-danger',
        Client     : 'light-warning',
        // Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.perms=JSON.parse(localStorage.getItem('access'));
    this.getCategory();
    this.UserID=JSON.parse(localStorage.getItem('userData'))
    // this.$http.get(`${config.API_BASE_URL}/api/auth/users`)
    //   .then(res => { this.users=res.data })
      
  },
}
</script>
<style scoped>
a.btn.btn-icon.btn-gradient-success.collapsed {

    margin-right: 6px;
    margin-left: 8px;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

